import AlertBars from './AlertBars';
import HowTo from './HowTo';
import Support from './Support';
import Plan from './Plan';
import AlertBar from './AlertBar';
import Submissions from './Submissions';

export const DEFAULT_ROUTES = {
    alertbars: AlertBars,
    alertbar: AlertBar,
    howto: HowTo,
    support: Support,
    plan: Plan,
    submissions: Submissions
};

export const INIT_ROUTES = {
    plan: Plan,
    support: Support
};
