import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

export default function Link({ children, url = '', external, ref, ...rest }) {

    if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
        rest.target = '_blank';
        rest.rel = 'noopener noreferrer';
        return <a href={url} {...rest}>{children}</a>;
    }

    return <ReactRouterLink to={url} {...rest}>{children}</ReactRouterLink>;

}