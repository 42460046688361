import React, { createContext, useContext } from "react";

//User context
const UserContext = createContext();

//User provider
export const UserProvider = ({ children, user }) => {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

//User hook function
export const useUser = () => {
    return useContext(UserContext);
}
