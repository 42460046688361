import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, ChoiceList, TextField } from '@shopify/polaris';

export default function Basic ({ name, isPublic, setName, setIsPublic, statusTypes }) {

	//Used hooks
	const { t } = useTranslation();

	return (
		<Stack vertical>
			<div style={{ maxWidth: 300, marginBottom: 10 }}>
				<TextField label={t('basic.name')} value={name} onChange={setName}/>
			</div>
			<ChoiceList title={t('basic.status')} selected={isPublic ? statusTypes.public.value : statusTypes.draft.value}
				onChange={(value) => setIsPublic(value[0] === statusTypes.public.value)}
				choices={Object.keys(statusTypes).map((el) => statusTypes[el])}/>
		</Stack>
	);

}