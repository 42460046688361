import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, RadioButton } from '@shopify/polaris';

export default function Layout ({ selectedAlertBar, changeSelectedAlertBar, generateBarHTML, layoutElements, defaultContainer, defaultCloseButton }) {

	//Get translations
	const { t } = useTranslation();

	return (
		<Stack vertical>
			<p>{t('layout.chooseLayout')}</p>
			{layoutElements.map((item) => {
				const { barHtml } = generateBarHTML(true, defaultContainer, defaultCloseButton, item.content);
				return <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: 15 }}>
					<RadioButton id={item.id} checked={selectedAlertBar === item.id} onChange={changeSelectedAlertBar}/>
					<div style={{ flex: 1, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: barHtml }}></div>
				</div>;
			})}
		</Stack>
	);

}