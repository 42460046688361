import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, Form, FormLayout, TextField, Button, Card } from "@shopify/polaris";
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { useToasts } from 'react-toast-notifications';
import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { API_URL } from "../config";
import { useUser } from '../utils';

export default function Support () {

	//Used hooks
	const app = useAppBridge();
	const history = useHistory();
	const { user } = useUser();
	const { addToast } = useToasts();
	const { t } = useTranslation();

	//Used state
	const [ loading, setLoading ] = useState(false);
	const [ email, setEmail ] = useState(user?.user?.email || '');
	const [ subject, setSubject ] = useState('');
	const [ message, setMessage ] = useState('');

	//Used methods
	const handleSubmit = (_event) => {
		if (!email) {
			addToast(t('support.emailErr'), { appearance: 'error' });
			return;
		}
		if (!subject) {
			addToast(t('support.subjectErr'), { appearance: 'error' });
			return;
		}
		if (!message) {
			addToast(t('support.messageErr'), { appearance: 'error' });
			return;
		}
		setLoading(true);
		authenticatedFetch(app)(API_URL + '/u/support', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, subject, message })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			addToast(t('support.sentMsg'), { appearance: 'success' });
			setEmail('');
			setSubject('');
			setMessage('');
		})
		.catch((err) => addToast(t('common.error') + ' ' + err.message, { appearance: 'error' }))
		.finally(() => setLoading(false));
	}

	return (
		<Page fullWidth title={t('menu.support')}
			secondaryActions={[{ content: t('common.goBack'), icon: ChevronLeftMinor, onAction: () => history.goBack() }]}>
			<div style={{ paddingTop: 10, maxWidth: 600, margin: 'auto' }}>
				<Card title={t('support.sendMessage')} sectioned>
					<Form onSubmit={handleSubmit}>
						<FormLayout>
							<TextField value={email} onChange={setEmail} label={t('support.email')} type="email"/>
							<TextField value={subject} onChange={setSubject} label={t('support.subject')} type="text"/>
							<TextField value={message} onChange={setMessage} label={t('support.message')} type="text" multiline={3}/>
							<div style={{ marginTop: 25 }}/>
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button submit primary loading={loading}>{t('support.send')}</Button>
							</div>
						</FormLayout>
					</Form>
				</Card>
			</div>
		</Page>
	);

}