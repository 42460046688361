import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@shopify/polaris';
import { ICONS } from '../config';

export default function IconPicker ({ title, value, changeValue=()=>{} }) {

	const { t } = useTranslation();
    const [ show, setShow ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ items, setItems ] = useState(ICONS);

	return <div style={{ position: 'relative' }}>
        {title ? <><span>{title}</span><div style={{ marginBottom: 5 }}></div></> : null}
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ outline: '1px solid black', width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <i style={{ color: 'black', fontSize: 16 }} className={value}/>
            </div>
            <div style={{ marginRight: 20 }}></div>
            <Button plain onClick={() => setShow(!show)}>{t('common.change')}</Button>
        </div>
        <div style={{ marginBottom: 10 }}></div>
        {show && <div style={{ overflow: 'auto', position: 'absolute', padding: 10, width: 200, height: 300,
            outline: '2px solid black', backgroundColor: 'white', zIndex: 99 }}>
            <TextField placeholder={t('common.search')} type="text" value={search}
                onChange={(value) => {
                    setSearch(value);
                    if (!value) setItems(ICONS);
                    else setItems(ICONS.filter((item) => item.match(value)));
                }}/>
            <div style={{ marginBottom: 10 }}></div>
            <div style={{ display: 'grid', gridGap: '15px', gridTemplateColumns: "repeat(4, 1fr)" }}>
                {items.map((item, i) => (
                    <i key={i} style={{ cursor: 'pointer', color: 'black', fontSize: 20 }} className={item} onClick={() => changeValue(item)}/>
                ))}
            </div>
        </div>}
    </div>;

}