import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as RouterProvider, Switch, Route, Redirect } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { AppProvider, Spinner, Banner } from "@shopify/polaris";
import { Provider as BridgeProvider } from '@shopify/app-bridge-react';
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { Redirect as IFrameRedirect } from '@shopify/app-bridge/actions';
import { ToastProvider } from 'react-toast-notifications';
import { initLocalization } from '../localization';
import { Link, UserProvider } from "../utils";
import { SHOPIFY_API_KEY, REDIRECT_URL, API_URL } from "../config";
import { DEFAULT_ROUTES, INIT_ROUTES } from "../pages";

export default function Root () {

	//Get host & shop parameters
	const urlParams = new URLSearchParams(location.search);
	const host = urlParams.get('host') || null;
	const shop = urlParams.get('shop') || null;
	const charge_id = urlParams.get('charge_id');
	if (!host || !shop) {
		//Redirect if not called as embedded
		location.href = REDIRECT_URL;
		return null;
	}

	//Check if first time
	var isFirstTimeData = 'false';
	try { isFirstTimeData = localStorage.getItem('first_time') || 'true'; }
	catch {}

    //Used state
    const [ loading, setLoading ] = useState(true);
    const [ errorTitle, setErrorTitle ] = useState('Error!');
    const [ error, setError ] = useState(null);
    const [ user, setUser ] = useState(null);
	const [ isFirstTime, setIsFirstTime ] = useState(isFirstTimeData === 'true');

	//Used attributes
	let redirect = null;
	const appBridgeRef = useRef(null);
	const isCharge = user && user.charge;
	const isFree = user && user.free;
	const ROUTES = isCharge ? DEFAULT_ROUTES : INIT_ROUTES;
	const routesNames = Object.keys(ROUTES);

	//Effect for handling data
	useEffect(() => {
		//Set redirect component
		redirect = IFrameRedirect.create(appBridgeRef.current.app);
		//Check billing charge
		const promise = !charge_id ? Promise.resolve() : authenticatedFetch(appBridgeRef.current.app)(API_URL + '/u/billing?id=' + charge_id);
		//Get current user and redirect if invalid
		let language = null;
		promise.then(() => authenticatedFetch(appBridgeRef.current.app)(API_URL + '/u/getuser'))
        .then((res) => res.json())
        .then((res) => {
            if (res.error) throw res.error;
			if (res.user) {
				if (res.user.locale) language = res.user.locale.slice(0, 2);
                setUser(res);
            }
        })
        .catch((err) => {
			if (err.code === 100 || err.code === 101 || err.code === 102 || err.code === 103) {
				//Handle unauthorized or app scopes's change or invalid access token or invalid session token
				redirect.dispatch(IFrameRedirect.Action.REMOTE, API_URL + '/login?shop=' + shop);
				setErrorTitle(undefined);
				setError(err.message + ' | Reloading now...');
			} else {
				setError(err.message);
			}
		})
        .finally(() => {
			initLocalization(language);
			setLoading(false);
		});
	}, []);

	return (
		<RouterProvider>
			<I18nextProvider i18n={i18n}>
				<ToastProvider autoDismiss placement="top-center">
					<AppProvider linkComponent={Link}>
						<BridgeProvider config={{ apiKey: SHOPIFY_API_KEY, host, forceRedirect: true }} ref={appBridgeRef}>
							<UserProvider user={{ user, setUser }}>
								{loading ? (
									<div style={{ textAlign: 'center', paddingTop: 60 }}><Spinner size="large"/></div>
								) : error ? (
									<div style={{ padding: '60px 40px' }}><Banner status="critical" title={errorTitle}><p>{error}</p></Banner></div>
								) : isFirstTime && isCharge ? (
									<DEFAULT_ROUTES.howto {...{ isFirstTime, setIsFirstTime }}/>
								) : (
									<Switch>
										{routesNames.map((name) => {
											const Component = ROUTES[name];
											return <Route key={name} path={"/" + name}><Component {...{ shop, isCharge, isFree }}/></Route>;
										})}
										<Redirect to={"/" + routesNames[0]}/>
									</Switch>
								)}
							</UserProvider>
						</BridgeProvider>
					</AppProvider>
				</ToastProvider>
			</I18nextProvider>
		</RouterProvider>
	);

}