import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Checkbox, ChoiceList, Combobox, Icon, Listbox, Stack, TextField } from '@shopify/polaris';
import { DragHandleMinor, SettingsMinor, DeleteMinor, SearchMinor, LockMinor } from '@shopify/polaris-icons';
import { ReactSortable } from "react-sortablejs";
import { ColorPicker, GradientPicker, IconPicker } from '../../utils';
import { TEXT_FONTS, PATTERN_IMGS } from '../../config';

export default function Content ({ elements, content, setContent, isNew, layoutChosen, setLayoutChosen, container, setContainer, closeButton, setCloseButton, currentElementId, setCurrentElementId, generateBarHTML }) {

	//Get translations
	const { t } = useTranslation();
	const { addToast } = useToasts();

	//Used variables
	const closeButtonActive = container.properties.closeButton.value;
	const currentElement = currentElementId === -1 ? container : currentElementId === -2 ? closeButton : content.find((item) => item.id === currentElementId);
	const contentHeight = '550px';
	const borderColor = '#cfcfcf';
	const bgColor = '#cfcfcf30';
	const { barHtml } = generateBarHTML(true, container, closeButton, content);

	//Used methods
	const changeComponentId = (evt) => {
		if (evt.pullMode === 'clone') {
			const newContent = [ ...content ];
			newContent[evt.newIndex].id = Math.ceil(Math.random() * 10000000);
			setContent(newContent);
			setCurrentElementId(newContent[evt.newIndex].id);
		}
	}

	const deleteElement = (id) => {
		setCurrentElementId(-1);
		setContent(content.filter((item) => item.id !== id));
	}

	const updateCurrentElementKey = (key, value) => {
		const newCurrentElement = { ...currentElement };
		newCurrentElement.properties[key].value = value;
		updateElement(newCurrentElement);
	}

	const updateElement = (element) => {
		if (currentElementId === -1) {
			setContainer(element);
		} else if (currentElementId === -2) {
			setCloseButton(element);
		} else {
			for (var index = 0; index < content.length; index++) { if (content[index].id === currentElementId) break; }
			const newContent = [ ...content ];
			newContent[index] = element;
			setContent(newContent);
		}
	}
	//Used effect
	useEffect(() => {
		if (isNew && !layoutChosen) setLayoutChosen(true);
		setCurrentElementId(-1);
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', padding: 2 }}>
			<div style={{ border: '2px solid ' + borderColor, borderRadius: 10, padding: 15 }}>
				<b>{t('content.preview')}</b>
				<div style={{ marginBottom: 15 }}/>
				<div dangerouslySetInnerHTML={{ __html: barHtml }}></div>
			</div>
			<div style={{ marginBottom: 15 }}/>
			<div style={{ display: 'flex', flexWrap: 'wrap-reverse' }}>
				<div style={{ flex: 1, marginRight: 15, marginBottom: 15, border: '2px solid ' + borderColor, borderRadius: 10,
					padding: 15, height: contentHeight, minWidth: 'fit-content', overflow: 'auto' }}>
					{currentElement && <>
						<p><b>{t('content.properties') + ': '}</b>{currentElement.name}</p>
						<div style={{ marginBottom: 15 }}/>
						<Stack vertical>
							{currentElement.properties && Object.keys(currentElement.properties).map((key, i) => {
								const property = currentElement.properties[key];
								if (property.activator) {
									const activatorProperty = currentElement.properties[property.activator]
									const value = activatorProperty.options ? activatorProperty.value[0] : activatorProperty.value;
									if (!property.activatorValues.includes(value)) return null;
								}
								if (property.info) {
									return <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
										{property.title ? <b style={{ marginBottom: property.value ? 5 : 0 }}>{property.title}</b> : null}
										{property.value ? property.value.map((item, j) => (
											<span key={j} style={{ marginBottom: j < property.value.length - 1 ? 5 : 0 }}>{item}</span>
										)) : null}
									</div>;
								} else if (property.options) {
									return <ChoiceList key={i} title={property.title} selected={property.value} choices={property.options}
										onChange={(value) => updateCurrentElementKey(key, value)}/>;
								} else if (property.field) {
									return <div key={i} style={{ width: property.width }}>
										<TextField label={property.title} type={property.field} value={property.value}
											placeholder={property.placeholder} onChange={(value) => {
												if (property.field === 'number' && !value) {
													addToast(t('content.valueErr'), { appearance: 'error' });
													return;
												}
												updateCurrentElementKey(key, value);
											}}/>
									</div>;
								} else if (property.multifield) {
									return <div key={i}>
										{property.title ? <><span>{property.title}</span><div style={{ marginBottom: 5 }}></div></> : null}
										<div style={{ display: 'flex' }}>
											{property.value.map((item, j) => <div key={j} style={{ width: property.width,
												marginRight: j < property.value.length - 1 ? 10 : undefined }}>
												<TextField type={property.multifield} value={item}
													onChange={(value) => {
														if (property.multifield === 'number' && !value) {
															addToast(t('content.valueErr'), { appearance: 'error' });
															return;
														}
														const newValue = [ ...property.value ];
														newValue[j] = value;
														updateCurrentElementKey(key, newValue);
													}}/>
											</div>)}
										</div>
									</div>;
								} else if (property.checkbox) {
									return <Checkbox key={i} label={property.title} checked={property.value}
										onChange={(value) => updateCurrentElementKey(key, value)}/>
								} else if (property.colorpicker) {
									return <ColorPicker key={i} title={property.title} value={property.value} changeValue={(value) => updateCurrentElementKey(key, value)}/>;
								} else if (property.gradientpicker) {
									return <GradientPicker key={i} title={property.title} value={property.value} changeValue={(value) => updateCurrentElementKey(key, value)}/>;
								} else if (property.iconpicker) {
									return <IconPicker key={i} title={property.title} value={property.value} changeValue={(value) => updateCurrentElementKey(key, value)}/>;
								} else if (property.fontpicker) {
									return <Combobox key={i} activator={<Combobox.TextField prefix={<Icon source={SearchMinor} color="inkLighter"/>}
										placeholder={t('content.searchFonts')} value={property.value}/>}>
										<Listbox onSelect={(value) => updateCurrentElementKey(key, value)}>
											{TEXT_FONTS.map((option, j) => <div key={j} style={{ fontFamily: option + ', sans-serif' }}>
												<Listbox.Option value={option} selected={option === property.value}>{option}</Listbox.Option>
											</div>)}
										</Listbox>
									</Combobox>;
								} else if (property.patternpicker) {
									return <div key={i} style={{ width: 'fit-content', display: 'grid', gridGap: '10px', gridTemplateColumns: "repeat(4, 1fr)" }}>
										{PATTERN_IMGS.map((item, j) => {
											return <div key={j} style={{ padding: 5, border: '1px solid #00000040', borderRadius: 5,
												cursor: 'pointer', backgroundColor: property.value === j ? '#00000020' : undefined }}
												onClick={() => updateCurrentElementKey(key, j)}>
												<div style={{ width: 100, height: 30, backgroundImage: `url(${item})` }}></div>
											</div>;
										})}
									</div>;
								} else {
									return null;
								}
							})}
						</Stack>
					</>}
				</div>
				<div style={{ flex: 1, display: 'flex', marginBottom: 15, height: contentHeight }}>
					<div style={{ flex: 1, marginRight: 15, border: '2px solid ' + borderColor, borderRadius: 10, padding: 15,
						minWidth: 'fit-content', overflow: 'auto' }}>
						<b>{t('content.content')}</b>
						<div style={{ marginBottom: 15 }}/>
						<div style={{ display: 'flex', alignItems: 'center', border: '2px solid ' + borderColor, borderRadius: 10, padding: 15,
							backgroundColor: currentElementId === -1 ? bgColor : undefined }} >
							<p>{container.name}</p>
							<div style={{ flex: 1 }}/>
							<SettingsMinor width={18} cursor="pointer" onClick={() => setCurrentElementId(-1)}/>
						</div>
						<div style={{ marginBottom: 15 }}/>
						<ReactSortable group="content" handle=".handle" list={content} setList={setContent}>
							{content.map((item) => (
								<div key={item.id} style={{ display: 'flex', alignItems: 'center', border: '2px solid ' + borderColor,
									borderRadius: 10, padding: 15, marginBottom: 15,
									backgroundColor: currentElementId === item.id ? bgColor : undefined }} >
									<DragHandleMinor width={18} className="handle" cursor="move"/>
									<div style={{ marginRight: 10 }}/>
									<p>{item.name}</p>
									<div style={{ flex: 1 }}/>
									<DeleteMinor width={18} cursor="pointer" onClick={() => deleteElement(item.id)}/>
									<div style={{ marginRight: 5 }}/>
									<SettingsMinor width={18} cursor="pointer" onClick={() => setCurrentElementId(item.id)}/>
								</div>
							))}
						</ReactSortable>
						{closeButtonActive && <div style={{ display: 'flex', alignItems: 'center', border: '2px solid ' + borderColor,
							borderRadius: 10, padding: 15, backgroundColor: currentElementId === -2 ? bgColor : undefined }} >
							<p>{closeButton.name}</p>
							<div style={{ flex: 1 }}/>
							<SettingsMinor width={18} cursor="pointer" onClick={() => setCurrentElementId(-2)}/>
						</div>}
					</div>
					<div style={{ width: 100, border: '2px solid ' + borderColor, borderRadius: 10, padding: '15px 15px 0 15px',
						minWidth: 'fit-content', overflow: 'auto' }}>
						<b>{t('content.elements')}</b>
						<div style={{ marginBottom: 15 }}/>
						<ReactSortable filter=".ignore" group={{ name: "content", pull: "clone", put: false }} sort={false} list={elements}
							setList={()=>{}} onEnd={changeComponentId}>
							{elements.map((item) => (
								<div key={item.id} style={{ textAlign: 'center', cursor: 'move', border: '2px solid ' + borderColor,
									borderRadius: 10, padding: '10px 10px 7px 10px', marginBottom: 15, position: 'relative' }}
									className={item.locked ? 'ignore' : undefined}
									title={item.locked ? item.name + ' - ' + t('common.unlockMsg') : item.name}>
									<Icon source={item.icon} color={item.locked ? 'subdued' : undefined}/>
									{item.locked && <div style={{ position: 'absolute', top: 0, right: 5 }}><LockMinor width={10} /></div>}
								</div>
							))}
						</ReactSortable>
					</div>
				</div>
			</div>
		</div>
	);

}