import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, ChoiceList, Button } from '@shopify/polaris';

export default function Frequency ({ isFree, frequency, setFrequency, frequencyTypes }) {

	//Used hooks
	const { t } = useTranslation();

	return <div>
		{isFree && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<Button url="/plan" plain>{t('common.unlockMsg')}</Button>
		</div>}
		<Stack vertical>
			<ChoiceList disabled={isFree} title={t('frequency.title')} selected={frequency}
				onChange={(value) => setFrequency(value[0])}
				choices={Object.keys(frequencyTypes).map((el) => frequencyTypes[el])}/>
		</Stack>
	</div>;

}