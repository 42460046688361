import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import { Button } from '@shopify/polaris';

export default function GradientPicker ({ title, value=[], changeValue=()=>{} }) {

	const { t } = useTranslation();
    const [ show, setShow ] = useState(false);

	return <div>
        {title ? <><span>{title}</span><div style={{ marginBottom: 5 }}></div></> : null}
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 25, height: 25, backgroundColor: value[0], outline: '1px solid black', marginRight: 10 }}></div>
            <span style={{ marginRight: 10 }}>{value[0]}</span>
            <div style={{ width: 25, height: 25, backgroundColor: value[1], outline: '1px solid black', marginRight: 10 }}></div>
            <span style={{ marginRight: 20 }}>{value[1]}</span>
            <Button plain onClick={() => setShow(!show)}>{t('common.change')}</Button>
        </div>
        <div style={{ marginBottom: 10 }}></div>
        {show && <div style={{ display: 'flex' }}>
            {value.map((item, j) => <div key={j} style={{ marginRight: j < value.length - 1 ? 15 : undefined }}>
                <ChromePicker disableAlpha color={item} onChangeComplete={(color) => {
                    const newValue = [ ...value ];
                    newValue[j] = color.hex;
                    changeValue(newValue);
                }}/>
            </div>)}
        </div>}
    </div>;

}