import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Page, Pagination } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from '@shopify/polaris-icons';

const images = [
	require("../assets/img/howto/1.png"), require("../assets/img/howto/2.png"), require("../assets/img/howto/3.png"),
	require("../assets/img/howto/4.png"), require("../assets/img/howto/5.png"), require("../assets/img/howto/6.png"),
	require("../assets/img/howto/7.png"), require("../assets/img/howto/8.png"), require("../assets/img/howto/9.png")
];

export default function HowTo ({ isFirstTime, setIsFirstTime }) {

	//Used hooks
	const history = useHistory();
	const { t } = useTranslation();

	//Used state
	const [ index, setIndex ] = useState(0);

	//Used attributes
	const instructions = [
		...images.map((image, i) => { return { text: t('howTo.message' + (i + 1).toString()), component: <img style={{ border: '#232323 1px solid', width: '100%' }} src={image}/> } }),
		{ component: <iframe style={{ width: '100%', height: '100%' }} frameBorder="0" allowFullScreen src="https://www.youtube.com/embed/ku-ZuMCnRfQ"></iframe> }
	]

	//Used methods
	const done = () => {
		try { localStorage.setItem('first_time', 'false'); }
		catch {}
		setIsFirstTime(false);
	}

	return (
		<Page fullWidth title={t('menu.howto')}
			secondaryActions={[isFirstTime ? {
				content: t('howTo.skip'), icon: ChevronRightMinor, onAction: done
			} : {
				content: t('common.goBack'), icon: ChevronLeftMinor, onAction: () => history.goBack()
			}]}>
			<div style={{ maxWidth: 450, margin: 'auto' }}>
				<Card sectioned>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ width: '100%', height: 350, display: 'flex', alignItems: 'center' }}>
							{instructions[index].component}
						</div>
						{instructions[index].text ? <>
							<div style={{ marginTop: 10 }}/>
							<p style={{ textAlign: 'center' }}>{instructions[index].text}</p>
						</> : null}
						<div style={{ marginTop: 30 }}/>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<Pagination hasPrevious={index > 0} hasNext={index < instructions.length - 1}
								onPrevious={() => setIndex(index - 1)} onNext={() => setIndex(index + 1)}
								label={(index + 1).toString() + '/' + instructions.length.toString()}/>
							{isFirstTime && index === instructions.length - 1 && <>
								<div style={{ marginRight: 10, flex: 1 }}></div>
								<Button primary onClick={done}>{t('howTo.continue')}</Button>
							</>}
						</div>
					</div>
				</Card>
			</div>
		</Page>
	);

}