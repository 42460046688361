import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Checkbox, Button, Tag, TextStyle, ChoiceList } from '@shopify/polaris';
import { CirclePlusMajor } from '@shopify/polaris-icons';

export default function Targeting ({ isFree, desktop, mobile, timePeriod, fromDate, toDate, countries, countriesInclude, urls, urlsInclude, setDesktop, setMobile, setTimePeriod, setFromDate, setToDate, setCountries, setCountriesInclude, setUrls, setUrlsInclude, handleCountriesModalChange, handleUrlsModalChange }) {

	//Used hooks
	const { t } = useTranslation();

	//Used variables
	const includeOptions = [
		{ value: 'include', label: t('targeting.include') },
		{ value: 'exclude', label: t('targeting.exclude') }
	];

	return <div>
		{isFree && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<Button url="/plan" plain>{t('common.unlockMsg')}</Button>
		</div>}
		<Stack vertical>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<p style={{ marginBottom: 5 }}>{t('targeting.devices')}</p>
				<Stack>
					<Checkbox disabled={isFree} label={t('targeting.desktop')} checked={desktop} onChange={setDesktop}/>
					<Checkbox disabled={isFree} label={t('targeting.mobile')} checked={mobile} onChange={setMobile}/>
				</Stack>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<p>{t('targeting.countries')}</p>
					<div style={{ marginRight: 10 }}/>
					<Button disabled={isFree} plain icon={CirclePlusMajor} onClick={handleCountriesModalChange}/>
				</div>
				<div style={{ marginBottom: 10 }}/>
				<ChoiceList disabled={isFree} choices={includeOptions} selected={countriesInclude ? 'include' : 'exclude'}
					onChange={(value) => setCountriesInclude(value[0] === 'include')}/>
				<div style={{ marginBottom: 5 }}/>
				<Stack alignment="center">
					{countries.length === 0 ? (
						<TextStyle variation="subdued">{t('targeting.emptyMsg')}</TextStyle>
					) : (
						countries.map((item, i) => (
							<Tag key={i} onRemove={() => setCountries(countries.filter((el) => el.value !== item.value))}>
								{item.label}
							</Tag>
						))
					)}
				</Stack>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<p>{t('targeting.urls')}</p>
					<div style={{ marginRight: 10 }}/>
					<Button disabled={isFree} plain icon={CirclePlusMajor} onClick={handleUrlsModalChange}/>
				</div>
				<div style={{ marginBottom: 10 }}/>
				<ChoiceList disabled={isFree} choices={includeOptions} selected={urlsInclude ? 'include' : 'exclude'}
					onChange={(value) => setUrlsInclude(value[0] === 'include')}/>
				<div style={{ marginBottom: 5 }}/>
				<Stack alignment="center">
					{urls.length === 0 ? (
						<TextStyle variation="subdued">{t('targeting.emptyMsg')}</TextStyle>
					) : (
						urls.map((item, i) => <Tag key={i} onRemove={() => setUrls(urls.filter((el) => el !== item))}>{item}</Tag>)
					)}
				</Stack>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
				<p style={{ marginBottom: 5 }}>{t('targeting.timePeriod')}</p>
				<Stack alignment="center">
					<Checkbox disabled={isFree} checked={timePeriod} onChange={setTimePeriod}/>
					<Stack alignment="center">
						<p>{t('targeting.from')}</p>
						<input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} disabled={isFree || !timePeriod}/>
						<p>{t('targeting.to')}</p>
						<input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} disabled={isFree || !timePeriod}/>
					</Stack>
				</Stack>
			</div>
		</Stack>
	</div>;

}