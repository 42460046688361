import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, Card, Button, ButtonGroup, Modal, TextContainer } from "@shopify/polaris";
import { ChevronLeftMinor, InviteMinor } from '@shopify/polaris-icons';
import { Redirect as IFrameRedirect } from '@shopify/app-bridge/actions';
import { useToasts } from 'react-toast-notifications';
import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { API_URL, FREE_PLAN_BARS_LIMIT } from "../config";
import { useUser } from '../utils';

export default function Plan ({ isCharge }) {

	//Used hooks
	const app = useAppBridge();
	const history = useHistory();
	const { user } = useUser();
	const { addToast } = useToasts();
	const { t } = useTranslation();

	//Used attributes
	const activePlan = user?.charge || null;

	//Used state
	const [ annual, setAnnual ] = useState(isCharge && !activePlan.name.toLowerCase().includes('annual') ? false : true);
	const [ changeLoading, setChangeLoading ] = useState(null);
	const [ freePlanLoading, setFreePlanLoading ] = useState(false);
	const [ freePlanActive, setFreePlanActive ] = useState(false);
	const [ freePlanMessage, setFreePlanMessage ] = useState('');

	//Used parameters
	const redirect = IFrameRedirect.create(app);
	const period = annual ? 'annual' : 'monthly';
	const planTypes = { basic: 'basic', premium: 'premium' };
	const planNames = { basic: 'Basic Plan', premium: { annual: 'Premium Plan Annual', monthly: 'Premium Plan' } };
	const planPrices = { premium: { annual: 100, monthly: 9.99 } };
	const isBasic = isCharge && activePlan.name.toLowerCase().includes(planTypes.basic);
	const isPremium = isCharge && activePlan.name.toLowerCase().includes(planTypes.premium);

	//Used methods
	const handleFreePlanChange = () => setFreePlanActive(!freePlanActive);

	const checkFreePlan = (type) => {
		setChangeLoading(type);
		authenticatedFetch(app)(API_URL + '/u/count')
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.count && res.count >= FREE_PLAN_BARS_LIMIT) {
				if (res.count === FREE_PLAN_BARS_LIMIT) setFreePlanMessage('');
				else setFreePlanMessage(t('plans.dataDeleteMsg'));
				handleFreePlanChange();
				setChangeLoading(null);
			} else {
				changeFreePlan(type, false);
			}
		})
		.catch((err) => {
			addToast(t('common.error') + ' ' + err.message, { appearance: 'error' });
			setChangeLoading(null);
		});
	}

	const changeFreePlan = (type, modal) => {
		if (modal) setFreePlanLoading(true);
		authenticatedFetch(app)(API_URL + '/u/freeplan', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: planNames[type] })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			redirect.dispatch(IFrameRedirect.Action.APP, '/');
		})
		.catch((err) => addToast(t('common.error') + ' ' + err.message, { appearance: 'error' }))
		.finally(() => {
			if (modal) setFreePlanLoading(false);
			else setChangeLoading(null);
		});
	}

	const changePaidPlan = (type) => {
		setChangeLoading(type);
		authenticatedFetch(app)(API_URL + '/u/billing', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: planNames[type]?.[period], type, annual })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.confirmation_url) redirect.dispatch(IFrameRedirect.Action.REMOTE, res.confirmation_url);
			else throw new Error(t('plans.chargeErr'));
		})
		.catch((err) => addToast(t('common.error') + ' ' + err.message, { appearance: 'error' }))
		.finally(() => setChangeLoading(null));
	}

	return (
		<Page fullWidth title={!isCharge ? t('plans.selectPlan') : t('menu.plan')}
			secondaryActions={[!isCharge ? {
				content: t('menu.support'), icon: InviteMinor, url: '/support'
			} : {
				content: t('common.goBack'), icon: ChevronLeftMinor, onAction: () => history.goBack()
			}]}>
			{!isPremium ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 30 }}>
				<ButtonGroup segmented>
					<Button primary={!annual} onClick={() => setAnnual(false)}>{t('plans.monthly')}</Button>
					<Button primary={annual} onClick={() => setAnnual(true)}>{t('plans.annual')}</Button>
				</ButtonGroup>
			</div> : <>
				<div style={{ marginTop: 10 }}></div>
			</>}
			<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', columnGap: '40px', rowGap: '30px' }}>
				<div>
					<Card title={isBasic ? activePlan.name : planNames.basic} sectioned>
						<div style={{ width: 300, height: 215, display: 'flex', flexDirection: 'column' }}>
							<ul style={{ margin: 0, paddingLeft: 25, overflowY: 'auto' }}>
								{t('plans.basicFeatures').split('*,').map((msg, i) => <li key={i}>{msg}</li>)}
							</ul>
							<div style={{ flex: 1 }}></div>
							<div style={{ marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
								{isBasic ? <>
									<div style={{ flex: 1 }}></div>
									<b style={{ color: 'green' }}>{t('plans.currentPlan')}</b>
								</> : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 90 }}>
									<b style={{ color: 'red' }}>{t('plans.free')}</b>
									<div style={{ marginTop: 5 }}></div>
									<Button fullWidth primary loading={changeLoading === planTypes.basic} onClick={() => checkFreePlan(planTypes.basic)}>{isCharge ? t('plans.changePlan') : t('plans.select')}</Button>
								</div>}
							</div>
						</div>
					</Card>
				</div>
				<div>
					<Card title={isPremium ? activePlan.name : planNames.premium[period]} sectioned>
						<div style={{ width: 300, height: 215, display: 'flex', flexDirection: 'column' }}>
							<ul style={{ margin: 0, paddingLeft: 25, overflowY: 'auto' }}>
								{t('plans.premiumFeatures').split('*,').map((msg, i) => <li key={i}>{msg}</li>)}
							</ul>
							<div style={{ flex: 1 }}></div>
							{isPremium && activePlan.billing_on ? <>
								<p style={{ marginTop: 10 }}>{t('plans.billingDate') + ': '}<b>{activePlan.billing_on.substr(0, 10).replaceAll('-', '/')}</b></p>
							</> : null}
							<div style={{ marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
								{isPremium ? <>
									<div style={{ flex: 1 }}></div>
									<b style={{ color: 'green' }}>{t('plans.currentPlan')}</b>
								</> : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 90 }}>
									<b style={{ color: 'red' }}>{planPrices.premium[period].toString() + '$ ' + (annual ? t('plans.perYear') : t('plans.perMonth'))}</b>
									<div style={{ marginTop: 5 }}></div>
									<Button fullWidth primary loading={changeLoading === planTypes.premium} onClick={() => changePaidPlan(planTypes.premium)}>{isCharge ? t('plans.changePlan') : t('plans.select')}</Button>
								</div>}
							</div>
						</div>
					</Card>
				</div>
			</div>
			<Modal open={freePlanActive} onClose={handleFreePlanChange} title={t('plans.changePlan')}
                primaryAction={{ content: t('common.change'), destructive: true, onAction: () => changeFreePlan(planTypes.basic, true), loading: freePlanLoading }}
                secondaryActions={[{ content: t('common.cancel'), onAction: handleFreePlanChange }]}
            >
                <Modal.Section>
                    <TextContainer><b>{t('common.confirmMsg')}</b><br/><span>{freePlanMessage}</span></TextContainer>
                </Modal.Section>
            </Modal>
		</Page>
	);

}