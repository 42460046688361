import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, EmptyState, SkeletonBodyText, Card, Banner, Stack, Caption, ButtonGroup, Button, Heading, Pagination, TextContainer, Modal } from "@shopify/polaris";
import { CirclePlusOutlineMinor, QuestionMarkInverseMinor, CustomersMinor, InviteMinor, EditMinor, DeleteMajor, FormsMajor } from '@shopify/polaris-icons';
import { useToasts } from 'react-toast-notifications';
import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { API_URL } from "../config";
import EmptyImage from '../assets/img/emptystate.png';

export default function AlertBars () {

	//Used hooks
	const app = useAppBridge();
	const { t } = useTranslation();
	const { addToast } = useToasts();

	//Used state
	const [ loading, setLoading ] = useState(true);
	const [ deleteLoading, setDeleteLoading ] = useState(null);
	const [ deleteItem, setDeleteItem ] = useState(null);
    const [ deleteActive, setDeleteActive ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ items, setItems ] = useState([]);
	const [ count, setCount ] = useState(0);
	const [ page, setPage ] = useState(1);

	//Used variables
	const limit = 10;
	const pagesCount = Math.ceil(count / limit);
	const empty = items.length === 0;

	//Used methods
	const handleDeleteModalChange = () => {
		const newActive = !deleteActive;
		if (!newActive) setDeleteItem(null);
		setDeleteActive(newActive);
	}

	const deleteAlertBar = () => {
		const item = { ...deleteItem };
		handleDeleteModalChange();
		setDeleteLoading(item._id);
		//Delete html
		const promise = !item.metaId ? Promise.resolve() : authenticatedFetch(app)(API_URL + '/u/html', {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ id: item.metaId })
		});
		//Delete alert bar
		promise.then(() => authenticatedFetch(app)(API_URL + '/u/alertbar', {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ _id: item._id })
		}))
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (item.metaId) return authenticatedFetch(app)(API_URL + '/u/total', { method: 'POST' });
		})
		.then(() => pageSelect(1))
		.catch((err) => addToast(t('common.error') + ' ' + err.message, { appearance: 'error' }))
		.finally(() => setDeleteLoading(null));
	}

	const pageSelect = (value) => {
		setPage(value);
		fetch(value);
	}

	const fetch = (selectedPage=null) => {
		const usedPage = selectedPage !== null ? selectedPage : page;
		if (!loading) setLoading(true);
		if (error) setError(null);
		setItems([]);
		setCount(0);
		authenticatedFetch(app)(API_URL + `/u/alertbar?page=${usedPage.toString()}&limit=${limit.toString()}`)
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.count) setCount(res.count);
			if (res.items) setItems(res.items);
		})
		.catch((err) => setError(err.message))
		.finally(() => setLoading(false));
	}

	//Used effect
	useEffect(() => fetch(), []);

	return (
		<Page fullWidth title={t('menu.alertbars')}
			primaryAction={{ content: t('alertbars.create'), icon: CirclePlusOutlineMinor, url: '/alertbar' }}
			secondaryActions={[
				{ content: t('menu.support'), icon: InviteMinor, url: '/support' },
				{ content: t('menu.plan'), icon: CustomersMinor, url: '/plan' },
				{ content: t('menu.howto'), icon: QuestionMarkInverseMinor, url: '/howto' }
			]}
		>
			{loading ? (
				<div style={{ padding: '10px 20px' }}>
					<Card sectioned><SkeletonBodyText/></Card>
					<Card sectioned><SkeletonBodyText/></Card>
					<Card sectioned><SkeletonBodyText/></Card>
				</div>
			) : error ? (
				<div style={{ padding: '10px 20px' }}>
					<Banner status="critical" title={t('common.error')}>
						<p style={{ marginBottom: 10 }}>{error}</p>
						<Button onClick={() => fetch()}>{t('common.tryAgain')}</Button>
					</Banner>
				</div>
			) : empty ? (
				<EmptyState heading={t('alertbars.emptyHeader')} image={EmptyImage}
					action={{ content: t('alertbars.create'), icon: CirclePlusOutlineMinor, url: '/alertbar' }}
					secondaryAction={{ content: t('menu.howto'), icon: QuestionMarkInverseMinor, url: '/howto' }}
				>
					<p>{t('alertbars.emptyMsg')}</p>
				</EmptyState>
			) : (
				<div style={{ padding: '10px 20px' }}>
					{items.map((item) => <Card sectioned key={item._id}>
						<Stack alignment="center" distribution="equalSpacing">
							<Heading>
								{item.name}
								<Caption>{t('alertBars.created')}:&nbsp;{new Date(item.date).toLocaleDateString()}</Caption>
								<Caption>
									{t('alertBars.status')}:&nbsp;
									<span style={{ display: 'inline', color: item.isPublic ? 'green' : 'orange' }}>
										{item.isPublic ? t('alertBars.published') : t('alertBars.draft')}
									</span>
								</Caption>
							</Heading>
							<ButtonGroup>
								{item.isForm && <Button icon={FormsMajor} url={"/submissions?_id=" + item._id + '&name=' + item.name}>
									{t('alertBars.submissions')}
								</Button>}
								<Button destructive icon={DeleteMajor} onClick={() => {
									if (deleteLoading !== null || deleteItem !== null) return;
									setDeleteItem(item);
									handleDeleteModalChange();
								}} loading={deleteLoading === item._id}>
									{t('alertBars.delete')}
								</Button>
								<Button primary icon={EditMinor} url={"/alertbar?_id=" + item._id}>{t('alertBars.edit')}</Button>
							</ButtonGroup>
						</Stack>
					</Card>)}
					{pagesCount > 1 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ marginTop: 25 }}></div>
						<Pagination hasPrevious={page > 1} hasNext={page < pagesCount}
							onPrevious={() => pageSelect(page - 1)} onNext={() => pageSelect(page + 1)}
							label={page.toString() + '/' + pagesCount.toString() + ' - ' + count.toString()}
						/>
					</div>}
				</div>
			)}
			<Modal open={deleteActive} onClose={handleDeleteModalChange} title={t('alertBars.delete')}
				primaryAction={{ content: t('common.ok'), onAction: deleteAlertBar }}
				secondaryActions={[{ content: t('common.cancel'), onAction: handleDeleteModalChange }]}
			>
				<Modal.Section><TextContainer><p>{t('common.confirmMsg')}</p></TextContainer></Modal.Section>
			</Modal>
		</Page>
	);

}