import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, Card, Banner, Stack, ButtonGroup, Button, Select, Spinner, Modal, EmptyState, DataTable, Pagination, TextContainer } from "@shopify/polaris";
import { ChevronLeftMinor, DeleteMinor, ExportMinor } from '@shopify/polaris-icons';
import { useToasts } from 'react-toast-notifications';
import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { API_URL } from "../config";
import EmptyImage from '../assets/img/emptystate.png';

export default function Submissions ({ shop }) {

	//Used hooks
	const app = useAppBridge();
	const history = useHistory();
	const { addToast } = useToasts();
	const { t } = useTranslation();

	//Used state
	const [ loading, setLoading ] = useState(false);
    const [ deleteLoading, setDeleteLoading ] = useState(false);
    const [ exportLoading, setExportLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ range, setRange ] = useState('lastWeek');
	const [ fromDate, setFromDate ] = useState(new Date().toISOString().substr(0, 10));
	const [ toDate, setToDate ] = useState(new Date().toISOString().substr(0, 10));
    const [ exportActive, setExportActive ] = useState(false);
    const [ deleteActive, setDeleteActive ] = useState(false);
    const [ items, setItems ] = useState([]);
    const [ headings, setHeadings ] = useState([]);
    const [ count, setCount ] = useState(0);
    const [ page, setPage ] = useState(1);

    //Used variables
    const dateOptions = [
        { value: 'today', label: t('submissions.today') },
        { value: 'yesterday', label: t('submissions.yesterday') },
        { value: 'lastWeek', label: t('submissions.lastWeek') },
        { value: 'thisMonth', label: t('submissions.thisMonth') },
        { value: 'lastMonth', label: t('submissions.lastMonth') },
        { value: 'custom', label: t('submissions.custom') },
    ];
    const urlParams = new URLSearchParams(location.search);
    const _id = urlParams.get('_id');
    const name = urlParams.get('name');
    const limit = 20;
    const pagesCount = Math.ceil(count / limit);
	const empty = items.length === 0 && pagesCount === 0;
    const pageRef = useRef(page);
    const rangeRef = useRef(range);
    const fromDateRef = useRef(fromDate);
    const toDateRef = useRef(toDate);

	//Used methods
    const handleExportChange = () => setExportActive(!exportActive);
    const handleDeleteChange = () => setDeleteActive(!deleteActive);

    const exportSubmissions = () => {
        setExportLoading(true);
		authenticatedFetch(app)(API_URL + `/u/submissions?shop=${shop}&alertbar_id=${_id}&isExport=true&range=${rangeRef.current}&fromISO=${new Date(fromDateRef.current).toISOString()}&toISO=${new Date(toDateRef.current).toISOString()}`)
        .then((res) => res.json())
        .then((res) => {
            if (res.error) throw new Error(res.error.message);
            if (res.count === 0) throw new Error(t('submissions.exportEmptyErr'));
            if (res.items) {
                let items = '';
                const headings = [];
                res.items.forEach((item, j) => {
                    const values = [];
                    item.values.forEach((el) => {
                        if (!headings.includes(el.name)) {
                            headings.push(el.name);
                            values.push(el.value);
                        } else {
                            const index = headings.indexOf(el.name);
                            values[index] = el.value;
                        }
                    });
                    for (let i = 0; i < values.length; i++) { if (!values[i]) values[i] = ''; }
                    items += values.join(',');
                    if (j < res.items.length - 1) items += '\n';
                });
                const data = headings.join(',') + '\n' + items;
                const encodedUri = encodeURI(data);
                const link = document.createElement("a");
                link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
                link.setAttribute("download", "submissions.csv");
                document.body.appendChild(link);
                link.click();
            }
            setExportLoading(false);
            handleExportChange();
        })
        .catch((err) => {
            setExportLoading(false);
            addToast(t('common.error') + ' ' + err.message, { appearance: 'error' });
        });
	}

    const deleteSubmissions = () => {
        setDeleteLoading(true);
		authenticatedFetch(app)(API_URL + '/u/submissions', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ shop, many: true, alertbar_id: _id, range: rangeRef.current, fromISO: new Date(fromDateRef.current).toISOString(), toISO: new Date(toDateRef.current).toISOString() })
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) throw new Error(res.error.message);
            setDeleteLoading(false);
            handleDeleteChange();
            pageSelect(1);
        })
        .catch((err) => {
            setDeleteLoading(false);
            addToast(t('common.error') + ' ' + err.message, { appearance: 'error' });
        });
	}

    const deleteSubmission = (_id) => {
		if (confirm(t('common.confirmMsg'))) {
            authenticatedFetch(app)(API_URL + '/u/submissions', {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ shop, _id })
            })
            .then((res) => res.json())
            .then((res) => {
                if (res.error) throw new Error(res.error.message);
                fetch();
            })
            .catch((err) => addToast(t('common.error') + ' ' + err.message, { appearance: 'error' }));
        }
	}

    const changeDate = (from, to) => {
        if (from) {
            fromDateRef.current = from;
            pageRef.current = 1;
            setFromDate(from);
            setPage(1);
            fetch(1, null, from);
        } else if (to) {
            toDateRef.current = to;
            pageRef.current = 1;
            setToDate(to);
            setPage(1);
            fetch(1, null, null, to);
        }
    }

    const changeRange = (value) => {
        rangeRef.current = value;
        pageRef.current = 1;
        setRange(value);
        setPage(1);
        fetch(1, value);
    }

    const pageSelect = (value) => {
        pageRef.current = value;
		setPage(value);
		fetch(value);
	}

    const fetch = (selectedPage=null, selectedRange=null, selectedFromDate=null, selectedToDate=null) => {
		const usedPage = selectedPage !== null ? selectedPage : pageRef.current;
		const usedRange = selectedRange !== null ? selectedRange : rangeRef.current;
		const usedFromDate = selectedFromDate !== null ? selectedFromDate : fromDateRef.current;
		const usedToDate = selectedToDate !== null ? selectedToDate : toDateRef.current;
		if (!loading) setLoading(true);
		if (error) setError(null);
		setItems([]);
		setCount(0);
		authenticatedFetch(app)(API_URL + `/u/submissions?shop=${shop}&alertbar_id=${_id}&page=${usedPage.toString()}&limit=${limit.toString()}&range=${usedRange}&fromISO=${new Date(usedFromDate).toISOString()}&toISO=${new Date(usedToDate).toISOString()}`)
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.count) setCount(res.count);
			if (res.items) {
                const items = [];
                const headings = [];
                res.items.forEach((item) => {
                    const values = [];
                    item.values.forEach((el) => {
                        if (!headings.includes(el.name)) {
                            headings.push(el.name);
                            values.push(el.value);
                        } else {
                            const index = headings.indexOf(el.name);
                            values[index] = el.value;
                        }
                    });
                    for (let i = 0; i < values.length; i++) { if (!values[i]) values[i] = ''; }
                    values.push(<Button plain destructive icon={DeleteMinor} onClick={() => deleteSubmission(item._id)}/>);
                    items.push(values);
                });
                setItems(items);
                setHeadings(headings);
            }
		})
		.catch((err) => setError(err.message))
		.finally(() => setLoading(false));
	}

    //Used effect
    useEffect(() => fetch(), []);

	return (
		<Page fullWidth title={t('menu.submissions') + (name ? ' ' + t('submissions.for') + ' "' + name + '"' : '')}
			secondaryActions={[{ content: t('common.goBack'), icon: ChevronLeftMinor, onAction: () => history.goBack() }]}>
			<div style={{ padding: '10px 20px' }}>
                {(!_id || !name) ? (
                    <Banner status="critical" title={t('common.error')}><p>{t('submissions.paramsMissingErr')}</p></Banner>
                ) : (
                    <Card sectioned>
                        <Stack alignment="center" distribution="equalSpacing">
                            <Stack alignment="center">
                                <Select labelInline label={t('submissions.dateRange') + ':'} options={dateOptions} onChange={changeRange} value={range}/>
                                {range === 'custom' && <Stack alignment="center">
                                    <p>{t('submissions.from')}</p>
                                    <input type="date" value={fromDate} onChange={(e) => e.target.value ? changeDate(e.target.value, null) : null}/>
                                    <p>{t('submissions.to')}</p>
                                    <input type="date" value={toDate} onChange={(e) => e.target.value ? changeDate(null, e.target.value) : null}/>
                                </Stack>}
                            </Stack>
							<ButtonGroup>
								<Button destructive icon={DeleteMinor} onClick={handleDeleteChange}>{t('submissions.delete')}</Button>
								<Button primary icon={ExportMinor} onClick={handleExportChange}>{t('submissions.export')}</Button>
							</ButtonGroup>
						</Stack>
                        {loading ? (
                            <div style={{ textAlign: 'center', padding: '40px 0' }}><Spinner size="large"/></div>
                        ) : error ? (
                            <div style={{ marginTop: 20 }}>
                                <Banner status="critical" title={t('common.error')}>
                                    <p style={{ marginBottom: 10 }}>{error}</p>
                                    <Button onClick={() => fetch()}>{t('common.tryAgain')}</Button>
                                </Banner>
                            </div>
                        ) : empty ? (
                            <EmptyState heading={t('submissions.emptyHeader')} image={EmptyImage}>
                                <p>{t('submissions.emptyMsg')}</p>
                            </EmptyState>
                        ) : (<>
                            <div style={{ marginTop: 10 }}/>
                            <DataTable rows={items} columnContentTypes={headings.map(() => 'text')} headings={[ ...headings, '' ]}
                                footerContent={(pagesCount > 1 || page > 1) && <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Pagination hasPrevious={page > 1} hasNext={page < pagesCount}
                                        onPrevious={() => pageSelect(page - 1)} onNext={() => pageSelect(page + 1)}
                                        label={page.toString() + '/' + pagesCount.toString() + ' - ' + count.toString()}
                                    />
                                </div>}
                            />
                        </>)}
				    </Card>
                )}
			</div>
            <Modal open={exportActive} onClose={handleExportChange} title={t('submissions.exportSubmissions')}
                primaryAction={{ content: t('submissions.export'), icon: ExportMinor, onAction: exportSubmissions, loading: exportLoading }}
                secondaryActions={[{ content: t('submissions.cancel'), onAction: handleExportChange }]}
            >
                <Modal.Section>
                    <TextContainer><b>{t('common.confirmMsg')}</b><br/><span>{t('submissions.exportMsg')}</span></TextContainer>
                </Modal.Section>
            </Modal>
            <Modal open={deleteActive} onClose={handleDeleteChange} title={t('submissions.deleteSubmissions')}
                primaryAction={{ content: t('submissions.delete'), destructive: true, icon: DeleteMinor, onAction: deleteSubmissions, loading: deleteLoading }}
                secondaryActions={[{ content: t('submissions.cancel'), onAction: handleDeleteChange }]}
            >
                <Modal.Section>
                    <TextContainer><b>{t('common.confirmMsg')}</b><br/><span>{t('submissions.deleteMsg')}</span></TextContainer>
                </Modal.Section>
            </Modal>
		</Page>
	);

}